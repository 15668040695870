<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>{{ rowData.accountName }}</td>
    <td>{{ rowData.taxName }}</td>
    <td>{{ rowData.deptName }}</td>
    <td>{{ rowData.barcode }}</td>
    <td>{{ rowData.goodsName }}</td>
    <td>{{ rowData.serviceCode }}</td>
    <td>{{ newUsedName }}</td>
    <td>{{ rowData.amount | currency }}</td>
    <td>{{ rowData.debit | currency }}</td>
  </tr>
</template>

<script>
export default {
  name: "PopInfraVoucherListLine",
  props: {
    rowData: Object,
    index: Number,
  },
  computed: {
    newUsedName() {
      if (this.rowData.newUsed === "") return "";
      return this.rowData.newUsed === "NEW" ? "신규" : "중고";
    },
  },
};
</script>
