<template>
  <div class="blank_asset2">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">{{ certificateDesc }}</strong>
      </div>
      <div class="pos_right">
        <span class="txt_info_888">통화코드 : {{ rowData.currency }}</span>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col style="width: 388px" />
          <col style="width: 170px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>지급처</th>
            <td>{{ companyName }}</td>
            <th>전표일자</th>
            <td>{{ rowData.voucherDate }}</td>
          </tr>
          <tr>
            <th>증빙일자</th>
            <td>{{ rowData.proofDate }}</td>
            <th>저장상태로 전표 생성</th>
            <td>
              <div class="box_choice">
                <div class="item_choice">
                  <!-- disabled 상태시 disabled 클래스 추가 -->
                  <input
                    id="voucherSaveModeYn"
                    v-model="rowData.voucherSaveModeYn"
                    type="checkbox"
                    class="inp_choice"
                    name="voucherSaveModeYn"
                    :value="true"
                  />
                  <label class="lab_choice" for="voucherSaveModeYn">
                    <span class="ico_account ico_check" />저장상태로 전표 생성
                  </label>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tbl_comm tbl_list tbl_list_sm12">
      <table>
        <colgroup>
          <col style="width: 50px" />
          <!-- No. -->
          <col style="width: 130px" />
          <!-- 계정과목 -->
          <col style="width: 130px" />
          <!-- 세목 -->
          <col style="width: 134px" />
          <!-- 부서 -->
          <col style="width: 114px" />
          <!-- 바코드 -->
          <col style="width: 130px" />
          <!-- 자산명 -->
          <col style="width: 126px" />
          <!-- 프로젝트코드 -->
          <col style="width: 56px" />
          <!-- 구분 -->
          <col style="width: 100px" />
          <!-- 수량 -->
          <col style="width: 150px" />
          <!-- 금액 -->
        </colgroup>
        <thead>
          <tr>
            <th>No.</th>
            <th>계정과목</th>
            <th>세목</th>
            <th>부서</th>
            <th>바코드</th>
            <th>자산명</th>
            <th>프로젝트코드</th>
            <th>구분</th>
            <th>수량</th>
            <th>금액</th>
          </tr>
        </thead>
        <tbody>
          <PopInfraVoucherListLine
            v-for="(item, index) in voucherItems"
            :key="index"
            :index="index"
            :rowData="item"
            @onClickPopAccount="$emit('onClickPopAccount')"
            @onClickPopTax="$emit('onClickPopTax')"
            @onClickPopDept="$emit('onClickPopDept')"
            @onClickPopService="$emit('onClickPopService')"
            @onClickPopPartner="$emit('onClickPopPartner')"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import PopInfraVoucherListLine from "./PopInfraVoucherListLine";
export default {
  name: "PopInfraVoucherItem",
  components: {
    PopInfraVoucherListLine,
  },
  props: {
    certificateDesc: String,
    rowData: Object,
    companyName: String,
  },
  computed: {
    voucherItems() {
      //console.log(this.voucher.voucherItems);
      // return this.rowData.serviceCode !== 'S999999';
      return this.rowData.voucherItems.filter((item) => item.serviceCode !== "S999999");
    },
  },
};
</script>
